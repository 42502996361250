.myb-animation-cards {
    height: 100%;
    display: flex;
    align-items: center;
    // background-image: url('../../../../resources/images/myb_animation_card_bg.jpg');
    // background-image: url("/content/dam/bhg/master/es/mybarcelo/hero-carousel/myb_animation_card_bg.jpg");
    // background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_animation_card_bg?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");

    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #1D2124;
}

.c-myb-animation-cards {
    &__wrapper {
        position: relative;
        width: 100%;
        min-height: 282px;
        &.mod--edit {
            .c-myb-animation-cards {
                &__card {
                    opacity: 1;
                }
            }
        }
    }

    &__card {
        position: absolute;
        display: flex;
        justify-content: space-between;
        padding: 26.89px 9.99px;
        width: 117.12px;
        height: 160.52px;
        border-radius: 12.29px;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;

        &-essential {
            top: 50.04px;
            left: 19%;
            // background-image: url('../../../../resources/images/myb_essential_card.png');
            // background-image: url("/content/dam/bhg/master/es/mybarcelo/hero-carousel/myb_essential_card.png");
            // background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_essential_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");
            background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_essential_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");

            &.mod--display-card {
                animation: displayCard 0.4s ease forwards;
            }
        }

        &-special {
            top: 32.36px;
            left: 33%;
            // background-image: url('../../../../resources/images/myb_special_card.png');
            // background-image: url("/content/dam/bhg/master/es/mybarcelo/hero-carousel/myb_special_card.png");
            // background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_special_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");
            background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_special_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");

            &.mod--display-card {
                animation: displayCard 0.4s ease forwards;
            }
        }

        &-unique {
            top: 63.75px;
            left: 49%;
            // background-image: url('../../../../resources/images/myb_unique_card.png');
            // background-image: url("/content/dam/bhg/master/es/mybarcelo/hero-carousel/myb_unique_card.png");
            // background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_unique_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");
            background-image: url("https://s7g10.scene7.com/is/image/barcelo/myb_unique_card?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");

            &.mod--display-card {
                animation: displayCard 0.4s ease forwards;
            }

            span {
                color: @white;
            }
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
    }

    &__tag {
        font-size: 3.84px;
        line-height: 7.68px;
        font-weight: 400;
    }

    &__name, &__membership {
        font-size: 6.14px;
        line-height: 7.68px;
        font-weight: 400;
    }
}

// MEDIA - TABLET
@media (min-width: 481px) {
    .c-myb-animation-cards {
        &__card {
            &-essential {
                top: 15%;
                left: 33%;
            }
    
            &-special {
                top: 10%;
                left: 45%;
            }
    
            &-unique {
                top: 17%;
                left: 58%;
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-myb-animation-cards {
        &__wrapper {
            min-height: 562px;
        }
        &__card {
            padding: 50px 18.68px;
            width: 219.13px;
            height: 300.32px;
            border-radius: 22.99px;

            &-essential {
                top: 29%;
                left: 19%;
            }

            &-special {
                top: 17%;
                left: 36%;
            }

            &-unique {
                top: 31%;
                left: 54%;
            }
        }

        &__tag {
            font-size: 7.18px;
            line-height: 14.37px;
        }

        &__name, &__membership {
            font-size: 11.5px;
            line-height: 14.37px;
        }
    }
}

// Keyframes
@keyframes displayCard {
   from {
    opacity: 0;
   }

   to {
    opacity: 1;
   }
}