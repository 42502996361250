/* FAQ - MOBILE FIRST (~ TABLET) */
.c-l-hero {
  
    &__main {  
        position: relative;
        z-index: 1;
        &-image{
            > .cmp-image.main{
                left: -15px;
                width: 100vw;
                z-index: -1;
                position: absolute;
                height: 332px;
                img{
                    object-position: 10%;
                }
            }
           
            > .cmp-image.corner{
                display: none;
            }
        }    

        &__text{
            padding-top: 32px;  
            &-1, &-2{
                text-transform: uppercase;
            }     
            &-1{
                font-family: 'TT Norms Pro', sans-serif;           
                font-weight: 800;
                font-size: 32px;
            }
            &-2{
                font-family: 'Barlow Condensed', sans-serif;
                font-size: 28px;
                line-height: 30px;
                font-weight: 400;
            }
            &-2, &-3{
                padding-top: 16px;
            }
            &-1, &-2, &-3{
                .c-l-hero.unique & {
                   color: white;
                }
            }
            
        }
        &__cards{
            padding-top: 32px;
            &-1, &-2{
                border-radius: 16px;
                border: 1px solid @grey-30;
                width: 100%;
                height: 239px;
                background-color: white;
                display: flex;
                .c-l-hero.special &{
                    border: 1px solid #E7CE98;
                }
                .c-l-hero.unique &{
                    border: 1px solid black;
                }
            }
            &-1{
                margin-bottom: 24px;
                
                align-items: center;
               
                .cards-1__progress{
                    position: relative;
                    margin-left: 16px;
                    //#### SVG Progress
                    .svg-indicator {
                        width: 102px;
                        height: 102px;
                        //margin-left: -2px;
                        //margin-top: -1px;
                        transform: rotate(-90deg);
                    }
                    .svg-indicator-track,
                    .svg-indicator-indication {
                        cx: 50px;
                        cy: 50px;
                        r: 48px;
                        fill: transparent;
                        stroke-width: 4px;
                    }
    
                    .svg-indicator-track {
                        stroke: @grey-20;
                    }
                    .svg-indicator-indication {
                        stroke: #1D6173;
                        stroke-dasharray: 246.2px;
                        stroke-dashoffset: 188.4px;
                        .c-l-hero.special & {
                            stroke-dasharray: 304.2px;
                            stroke-dashoffset: 188.4px;
                        }
                        .c-l-hero.unique & {
                            stroke-dasharray: 492.2px;
                            stroke-dashoffset: 188.4px;
                        }
                    }       
                    //####
                    .cmp-image{
                        position: absolute;
                        width: 64px;
                        height: 64px;
                        left: 18px;
                        top: 20px;
                    }
                }
                .cards-1__right{
                    margin-left: 16px;
                    display: flex;
                    flex-flow: column;
                    &-name{
                        font-weight: 600;
                    }
                    &-level{
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                    &-num{
                        padding-top: 8px;
                    }
                    &-date{
                        padding-top: 4px;
                    }
                    &-cta{
                        padding-top: 8px;    
                        margin-bottom: 0;
                    }
                }
                
                
            }
            &-2{
                flex-flow: column;
                justify-content: space-between;
                padding: 24px;
                .cards-2__header{
                    padding-bottom: 16px;
                    font-family: 'Barlow Condensed', sans-serif;
                    display: flex;   
                    height: 80px;
                    //color: black;  /* Fallback: assume this color ON TOP of image */
                    //background: url(https://24.media.tumblr.com/tumblr_m87dri70zh1qzla33o1_500.gif);        
                    //background-size: cover;
                    //-webkit-background-clip: text;
                    //-webkit-text-fill-color: transparent;
                    color: @grey-30;
                    .c-l-hero.special &{
                        color: #E7CE98;
                    }
                    .c-l-hero.unique &{
                        color: black;
                    }
                    .cards-2__header-left{
                        font-size: 40px;
                        line-height: 41px;
                        align-self: flex-end;
                    }
                    .cards-2__header-right{
                        font-size: 80px;
                        line-height: 50px;
                    }
                    .cards-2__header-percentual{
                        font-size: 47px;
                        line-height: 37px;
                    }

                }
                .cards-2__content{
                    display: flex;
                    flex-flow: column;
                    
                    &-text-1{
                        font-weight: 400;
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                    &-text-2{
                        padding-top: 8px;
                        font-size: 14px;  
                        line-height: 20px;         
                    }
                    .cta{
                        padding-top: 8px;
                        margin-bottom: 0;
                        a{
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }

                }
            }

        }
       
    }
   
}

/* FAQ - DESKTOP */
@media (min-width: @desktop) {
    .c-l-hero {
        &__main {  
        
            &-image{
                > .cmp-image.main{
                    left: 0;
                    width: 100%;
                    height: 448px;
                    img{
                        object-position: 90%;
                    }
                }
               
                > .cmp-image.corner{
                    display: block;
                    position: absolute;
                    z-index: -1;
                    top: 107px;
                    right: 0;
                    height: 340.8px;
                    width: 407.4px;
                    img{
                        object-fit: contain;
                    }
                }    
            }
            &__text{
                padding-left: 64px;
                padding-top: 56px;
                max-width: 900px;
                &-1, &-2{
                    font-size: 52px;
                    line-height: 50px;
                }         
            }
            &__cards{
                padding-left: 64px;
                padding-right: 64px;
                padding-top: 36px;
                display: flex;
                &-1, &-2{
                    height: 300px;
                    width: 40%;
                }
                &-1{
                    width: 60%;
                    margin-right: 24px;
                    margin-bottom: 0;
                    .cards-1__progress{
                        //#### SVG Progress
                        .svg-indicator {
                            width: 188px;
                            height: 188px;
                        }
                        .svg-indicator-track,
                        .svg-indicator-indication {
                            cx: 94px;
                            cy: 94px;
                            r: 92px;
                            fill: transparent;
                            stroke-width: 4px;
                        }
                        .svg-indicator-indication {
                            stroke-dasharray: 477.2px;
                            stroke-dashoffset: 373.4px;
                            .c-l-hero.special &{
                                stroke-dasharray: 472.2px;
                                stroke-dashoffset: 251.4px;
                            }
                            .c-l-hero.unique &{
                                stroke-dasharray: 589.2px;
                                stroke-dashoffset: 5.4px;
                            }
                        }       
                        //####
                        .cmp-image{
                            position: absolute;
                            width: 128px;
                            height: 128px;
                            left: 30px;
                            top: 30px;
                        }
                    }    
                    .cards-1__right{
                        &-num{
                            padding-top: 20px;
                        }
                        &-cta{
                            padding-top: 20px;
                        }
                    }
                }
                &-2{
                    .cards-2__header{
                        height: 104px;
                        .cards-2__header-right{
                            font-size: 109px;
                            line-height: 79px;
                        }
                        .cards-2__header-percentual{
                            font-size: 47px;
                            line-height: 47px;
                        }
    
                    }
                }
    
            }
           
        }
    }     
}


/*  CARD 2 TEXTS */

.c-l-hero {
    &__main {
        &__cards-2 {
            justify-content: center;
            overflow: hidden;
            .cards-2__header {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 553px auto;
                display: flex;
                overflow: hidden;
                padding: 0;
                margin-bottom: 18px;
                align-items: flex-end;
                height: 84px;
                width: 100%;
                &>div, &>span, ::after {
                    background-color: white;
                    mix-blend-mode: screen;
                    height: 100%;
                    width: 100%;
                    color: black;
                }
                &-text {
                    white-space: nowrap;
                    display: flex;
                    align-items: flex-end;
                    font-size: 40px;
                    line-height: 40px;
                    text-transform: uppercase;
                }
                .percentage,
                .cards-2__header-text span:first-of-type {
                    position: relative;
                    font-size: 109px;
                    line-height: 96px;
                    margin-left: 6px;
                }
                .symbol, 
                .cards-2__header-text span:nth-of-type(2) {
                    position: relative;
                    font-size: 47px;
                    line-height: 47px;
                    margin-bottom: 43px;
                    top: 0;
                    right: 0;
                }
            }
            .cards-2__header {
                background-image: url("https://s7g10.scene7.com/is/image/barcelo/Essential?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm")
            }
        }
    }
    &.special {
        .c-l-hero__main {
            &__cards-2 {
                .cards-2__header {
                    background-image: url("https://s7g10.scene7.com/is/image/barcelo/Imagen%20(23)?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm")
                }
            }
        }

    }
    &.unique {
        .c-l-hero__main {
            &__cards-2 {
                .cards-2__header {
                    background-image: url("https://s7g10.scene7.com/is/image/barcelo/Uniquehero?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm")
                }
            }
        }

    }
}
