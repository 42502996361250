//MAP MODAL

// CURRENT BOOKINGS
.c-myb-current-bookings__item, .c-myb-booking-history__list__item, .myb-loyalty-info  {
    .c-hotel-map-card__modal  {
        display: none;
        &.mod--open {
            display: flex;
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
        }

        &.mod--expand {
            .c-hotel-map-card__modal__wrapper-outter {
                height: calc(70% + 30px);

            }
        }

        &__map-wrapper {
            height: 50%;
        }

        &__wrapper-outter {
            padding: 84px 16.5px 78px;
            height: calc(50% + 30px);
        }

        &__wrapper-inner {
            &__tag {
                font-family: 'Museo Sans', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0.0025em;
                color: #2D6229;
                background-color: #DDECD4;
                padding: 4px 8px;
                margin-bottom: 12px;
            }
            &__title {
                font-family: 'Museo Sans', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0.0015em;
                color: #384044;
                margin-bottom: 8px;
            }
    
            .c-hotel-map-card__content-links-item {
                display: block;
                font-family: 'Museo Sans', sans-serif;
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0.005em;
                color: #384044;
            }
            .c-hotel-map-card__modal__wrapper-inner__items {
                margin-top: 40px;

                .c-textimage {
                    padding: 0;
                    margin-bottom: 8px;
        
                    .icon {
                        display: none;
                    }

                    .generic-text {
                        .cmp-text {
                            font-family: 'Museo Sans', sans-serif;
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 24px;
                            letter-spacing: 0.005em;
                            color: #888C8F;
                        }
                    }
                }
            }

        }

        &-close {
            top: 71px;
        }

    }
}


@media screen and (min-width: @desktop) {
    .c-myb-current-bookings__item, .c-myb-booking-history__list__item, .myb-loyalty-info  {
        .c-hotel-map-card__modal  {
        
            &.mod--expand {
                .c-hotel-map-card__modal__wrapper-outter {
                    height: 100%;
    
                }
            }
    
            &__wrapper-outter {
                padding: 56px 41px 0px 64px;
                height: 100%;
            }

            &__map-wrapper {
                height: 100%;
            }
    
            &__wrapper-inner {
                padding-right: 0;
    
            }

            &-close {
                top: 16px;
            }
    
        }
    }
}