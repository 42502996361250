// Editable Author
.aem-AuthorLayer-Edit,
.aem-AuthorLayer-initial,
.aem-AuthorLayer-structure,
.aem-AuthorLayer-Layouting,
.aem-AuthorLayer-Developer,
.aem-AuthorLayer-Timewarp {


//BANNER XL
  .c-myb-banner-xl {
    &__info {
        &-texts {
            &__text {
                min-width: 150px;
            }
        }
        &-bottom {
            &__link {
                min-width: 150px;
            }
        }
    }
  }
}