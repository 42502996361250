/* FAQ - MOBILE FIRST (~ TABLET) */
.c-l-info {
    margin-top: 40px;
    &__header {
        &-title{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
        }
        &-description{
            margin-top: 16px;
            font-size: 16px;
            font-weight: 300;
        }
    }

    &__main {  
        margin-top: 24px;
        padding: 40px 24px 40px 24px;
        border-radius: 16px;
        border: 1px solid @grey-30;
        &__levels{
            display: flex;
            font-size: 14px;
            text-transform: uppercase;
            color: @grey-90;
            > .separator{
                height: 1px;
                width: 100%;
                background-color: @grey-38;
                margin-left: 8px;
                margin-right: 8px;
                margin-top: 16px
            }   
            > .level-1, > .level-2 , > .level-3{         
                white-space: nowrap;
                display: flex;
                flex-flow: column-reverse;
                align-items: center;
                .level-icon{
                    font-weight: 600;
                }
                
            }
            > .level-1 > .level-1-label, > .level-2 > .level-2-label, > .level-3 > .level-3-label{
                display: none;
            }   
            .c-l-info.essential &{
                > .level-2, > .level-3{
                    color: @grey-38
                }
            } 
            .c-l-info.special &{
                > .level-3{
                    color: @grey-38
                }
                > .level-2{
                    .icon-circle-uncheck::before{content: '\e92f';}
                }
            }
            .c-l-info.unique &{
                > .level-2, > .level-3{
                    .icon-circle-uncheck::before{content: '\e92f';}
                }
            }
        }
        &__target-1, &__target-2{
            color: #384044;
            > .c-progress-bar{
                margin: 16px 0 0 0;
                width: 100%;
                > .c-progress-bar__milestone{
                    > .wrap-icon{
                        z-index: 2;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: #2F5A76;
                        position: relative;
                        > .icon-full-star{
                            color: white;
                            font-size: 12px;
                            position: absolute;
                            top: 4px;
                            left: 4px;
                        }
                    }
                    > .c-progress-bar__milestone-bar{
                        &::before{
                            left: 0%;
                            background: linear-gradient(to right,#30a2bf 12.01%,#315973 60.41%);
                        }
                    }
                }
                
                .c-progress-bar__milestone-bar, .c-progress-bar__goal-bar {
                    border-radius: 24px;
                } 
            }
            > .bar-values{
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
            }

            > .target-1-text, > .target-2-text{
                margin-top: 32px;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 400;
                color: @grey-44;
            }
            > .target-1-description,  > .target-2-description{
                margin-top: 16px;   
                font-size: 16px;
                font-weight: 600;
                > .target-1-description-value, > .target-2-description-value{
                    color: #2F5A76;
                }
            }
            > .target-1-claim-base, > .target-1-claim-level, > .target-1-claim-expiring, > .target-2-claim-spend{
               
                color: #384044;
                border-radius: 8px;
                width: fit-content;
                font-size: 12px;
                padding-bottom: 4px;
                padding-top: 4px;
                padding-right: 8px;
                background-color: #30a2bf;
                margin-top: 16px;
                display: none;
                &.show{
                    display: flex
                }
                .icon-info{
                    font-weight: 600;
                    padding-left: 8px;
                    margin-top: 2px;
                } 
                .claim-text{
                    padding-left: 8px;
                    line-height: 16px;
                }
            }
            > .target-1-claim-base, > .target-1-claim-level{
                background-color: #E8F1E9;
            }
            > .target-1-claim-expiring, > .target-2-claim-spend{
                background-color: #FFCB96;
            }
        }
        &__target-1{
            > .c-progress-bar{
                > .c-progress-bar__milestone{
                    > .c-progress-bar__milestone-bar{
                        &::before{
                            width: var(--progBar, 0);
                        }
                    }
                    > .wrap-icon{
                        margin-left: calc(var(--progBar, 0) - 20px);
                    }
                }
            }
        }
        &__target-2{
            > .c-progress-bar{
                > .c-progress-bar__milestone{
                    > .c-progress-bar__milestone-bar{
                        &::before{
                            width: var(--progBar, 0);
                        }
                    }
                    > .wrap-icon{
                        margin-left: calc(var(--progBar, 0) - 20px);
                    }
                }
            }          
        }

        &__stays{
            margin-top: 32px;
            display: none;
            &.show{
                display: block;
            }
            &-btn-show{
                margin-top: 48px;
                color: #2F5A76;
                display: flex;
                justify-content: center;
                    
                > .c-cta{
                    display: flex !important;
                    align-items: center !important;
                    > .icon-simple-arrow{
                        transform: rotate(90deg);
                        transition-duration: 0.2s;
                        margin-left: 8px;
                        font-size: 24px;
                    }
                    &.active{
                        > .icon-simple-arrow{
                            transform: rotate(270deg);
                        }
                    }
                }         
            }

            &__cards{
                margin-top: 32px;
                display: none;
                &.show{
                    display: block;
                }
                > .cards-title{
                    margin-top: 48px;
                    text-transform: uppercase;
                    font-size: 14px;
                }
                > .cards-descr{
                    margin-top: 16px;
                    font-size: 16px;
                }
                
                > .cards-tabs {
                    margin-top: 24px;
                    > .tabs-btns{
                        display: flex; 
                        height: 38px;
                        padding-left: 8px;
                        overflow-x: auto;
                        scrollbar-width: none;
                        .tab {
                            margin-right: 24px;
                            font-size: 16px; 
                            font-weight: 300; 
                            text-transform: capitalize;
                            white-space: nowrap;
                            &.active{
                                font-weight: 400; 
                                &::before{
                                    width: calc(100% + 8px);
                                    left: -4px;
                                    content: '';
                                    height: 1px;
                                    background-color: #30A2BF;
                                    display: block;
                                    position: relative;
                                    top: 27px
                                }
                            }         
                        }
                    }
                    > .tabs-contents{
                        margin-top: 32px;
                        [data-tmpl-card]{
                            display: none;
                        }
                        .content{
                            display: none;
                            &.active{
                                display: block;
                            }
                        }
                        .card{
                            border: solid 1px @grey-38;
                            border-radius: 16px;
                            padding: 8px;
                            margin-bottom: 16px;
                            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
                            &__header{
                                display: flex;
                                align-items: center;
                                flex-flow: column;
                                align-items: flex-start;
                                >.claim{
                                    display: none;//## waitingo for clarify when use it
                                    font-size: 12px;
                                    font-weight: 400;
                                    background-color:#FFF9EC;
                                    border-radius: 8px;
                                    width: fit-content;
                                    padding: 1px 8px;
                                    margin-right: 8px;
                                }
                                >.date{
                                    font-size: 12px;
                                    font-weight: 300;
                                }
                            }
                            &__body{
                                margin-top: 16px;
                                display: flex;
                                justify-content: space-between;
                                flex-flow: column;
                                > .left{
                                    display: flex;
                                    width: 100%;
                                    > .cmp-image{
                                        width: 48px;
                                        height: 48px;
                                        min-width: 48px;
                                        > img{
                                            border-radius: 8px;
                                        }
                                    }
                                    > .text{
                                        padding-left: 16px;
                                        padding-right: 16px;
                                        display: flex;
                                        flex-flow: column;
                                        > .descr{
                                            font-weight: 400;
                                            font-size: 12px;
                                            text-transform: uppercase;
                                        }
                                        > .hotel{
                                            font-weight:600;
                                            font-size: 20px;
                                        }
                                        > .address{
                                            display: flex;
                                            font-size: 16px;
                                            margin-top: 8px;
                                            margin-left: -64px;
                                            > a{
                                                padding-left: 4px;
                                                top: -2px;
                                                position: relative;
                                            }
                                        }
                                    }
                                }
                                > .center{
                                    width: 48%;
                                    transform: translateY(100%);
                                    > .room-title{
                                        font-weight: 400;
                                        font-size: 12px;
                                        text-transform: uppercase;
                                        display: none;
                                    }
                                    > .room-type{
                                        font-weight: 600;           
                                    }  
                                }
                                > .right{
                                    text-align: right;
                                    align-self: self-end;
                                    width: 48%;
                                    opacity: 0;//#### at the moment we hide it , waiting to clarify it ####
                                    > .price-title{
                                        display: none;
                                        font-size: 12px;
                                        font-weight: 400;
                                        text-transform: uppercase;
                                    }
                                    > .price-value{
                                        font-size: 20px;
                                        font-weight: 600;
                                    }
                                    > .price-tax{
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                > .cards-bottom{
                    margin-top: 8px;
                    display: flex;
                    font-size: 16px;
                    font-weight: 600;
                    > .bottom-cta{
                        padding-left: 8px;
                        > .c-cta{
                            font-size: 16px;
                            font-weight: 400 !important;
                        }     
                    }
                }   
            }     
        }    
    }

    &__cta{
        margin-top: 32px;
        a{
            font-size: 16px;
            font-weight: 400 !important;
        }
    }
   
    .mod--disabled {
        text-decoration: none;
        pointer-events: none;

        a {
            text-decoration: none;
        }
    }
}

/* FAQ - DESKTOP */
@media (min-width: @desktop) {
    .c-l-info {
        margin-top: 48px;
        &__header {
            &-title{
                font-size: 28px;
                line-height: 36px;
            }
        }
       
        &__main {
            margin-top: 40px;
            padding: 40px 48px 40px 48px;
            &__levels{
                > .separator{
                    margin-left: 30px;
                    margin-right: 30px;
                    margin-top: 11px;
                }
                > .level-1, > .level-2 , > .level-3{     
                    flex-flow: row;    
                    > .level-icon{
                        margin-left: 8px;
                        margin-bottom: 3px;
                    }
                }
                > .level-1 > .level-1-value, > .level-2  > .level-2-value , > .level-3  > .level-3-value{
                    display: none;
                }
                > .level-1 > .level-1-label, > .level-2 > .level-2-label, > .level-3 > .level-3-label  {
                    display: block;
                } 
            }
            &__stays{
                margin-top: 48px;
                &__cards{
                    .cards-tabs {
                        > .tabs-contents{
                            .card{
                                padding: 24px;
                                &__header{
                                    flex-flow: row;
                                    > .date{
                                        font-size: 16px;
                                    }
                                }
                                &__body{
                                    margin-top: 24px;
                                    flex-direction: row;
                                    > .left{
                                        width: 60%;
                                        > .cmp-image{
                                            width: 70px;
                                            height: 70px;
                                            min-width: 70px;
                                        }
                                        > .text{
                                            display:block;
                                            padding-right: 8px;
                                            >.address{
                                                margin-top: 6px;
                                                margin-left: 0px;
                                            }
                                        }
                                    }
                                    > .center{
                                        width: 27%;
                                        transform: none;
                                        > .room-title{
                                            display: block;
                                        }      
                                    }
                                    > .right{
                                        width: auto;
                                        > .price-title{
                                            display: block;
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }       
    }     
}


