//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* MYBARCELÓ TRIGGER BANNER - MOBILE FIRST */
.myb-trigger-banner {
	.c-myb-trigger-banner {
		display: flex;
		flex-direction: column;
		box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
		&__content {
			position: relative;
			width: 100%;
			background-color: @myb-yellow;
			padding: 20px 26px 8px 26px;
			.c-myb-trigger-banner {
				&__logo {
					position: absolute;
					top: 20px;
					left: 34px;
					width: 150px;
					display: none;
				}
				&__heading,
				&__heading > * {
					font-size: 18px;
					line-height: 22px;
					font-weight: 500;
					text-align: center;
					padding: 0 4px;
				}
				&__hr {
					border-color: @grey-40;
				}
				&__features {
					display: flex;
					justify-content: space-around;
					flex-direction: column;
					margin: 10px 0;
					padding: 0px;
					.c-myb-trigger-banner {
						&__feature {
							position: relative;
							display: flex;
							flex-direction: column;
							width: 100%;
							height: auto;
							background-color: @white;
							margin: 0 8px;
							padding: 14px 20px;
							overflow: hidden;
							border-radius: 4px;

							& > *:not(.c-myb-trigger-banner__feature-hover) {
								opacity: 1;
								transition: .2s linear;
							}
							&-icon {
								margin-bottom: 5px;
								margin-top: 5px;
							}
							&-title,
							&-title > * {
								font-size: 18px;
								font-weight: 700;
								line-height: 22px;
								padding-bottom: 2px;
							}
							&-text,
							&-text > * {
								font-size: 16px;
								font-weight: 300;
								line-height: 19px;
								p {
									padding-bottom: 6px;
								}
							}
							&-icon--info {
								position: absolute;
								top: 14px;
								right: 14px;
								margin: auto;
								text-align: center;
								border: 1px solid @black;
								border-radius: 50%;
								width: 16px;
								height: 16px;
								font-size: 12px;
								line-height: 15px;
								&:before{
									content:'i';
									font-family: sans-serif;
									font-weight: normal;
									color:@black;
								}
							}
							&-hover {
								position: absolute;
								top: 130%;
								bottom: -130%;
								width: 100%;
								left: 0;
								height: 100%;
								padding: 8px 14px 34px;
								transition: .5s linear;
								z-index: 1;
								&__bg {
									transform: skewY(-6deg);
									transform-origin: top left;
									background-color: @myb-light-blue;
									position: absolute;
									width: 100%;
									left: 0;
									top: 26px;
									height: 150%;
								}
								&__content {
									position: relative;
									.c-myb-trigger-banner__feature-title {
										margin-bottom: 2px;
									}
								}
							}
							&:hover {
								cursor: pointer;
								.c-myb-trigger-banner__feature-hover {
									top: 0;
									bottom: 0;
									&__bg {
										top: 0;
									}
									&__content {
										.c-myb-trigger-banner__feature {
											&-title,
											&-text {
												opacity: 1;
											}
										}
									}
								}
								.c-myb-trigger-banner__feature-icon,
								.c-myb-trigger-banner__feature-title,
								.c-myb-trigger-banner__feature-text,
								.c-myb-trigger-banner__feature-icon--info {
									opacity: 0.5;
								}
							}

							// MODIFIERS
							&.mod--myb-feature__card {
								background: transparent;
								flex-direction: row;
								align-items: center;
								padding: 7px 0;
								.c-myb-trigger-banner__feature {
									&-icon {
										margin-right: 10px;
										width: 23px;
										height: 23px;
										.cmp-icon {
											width: 23px;
											height: 23px;
										}
									}
									&-title,
									&-title > * {
										font-size: 16px;
									}
									&-text,
									&-text > * {
										display: none;
									}
									&-icon--info {
										transform: translateY(-50%);
										top: 50%;
										display: none;
									}
									&-hover {
										display: none;
									}
								}
								&:hover {
									&>* {
										opacity: 1;
									}
								}
								
							}
							&.mod--myb-feature__maxi-card {
								background: transparent;
								flex-direction: row;
								align-items: center;
								padding: 7px 0;
								.c-myb-trigger-banner__feature {
									&-icon {
										margin-right: 10px;
										width: 23px;
										height: 23px;
										margin-top: 8px;
										margin-bottom: 2px;
										.cmp-icon {
											width: 23px;
											height: 23px;
										}
									}
									&-title,
									&-title > * {
										font-size: 16px;
									}
									&-text,
									&-text > * {
										display: none;
									}
									&-icon--info {
										transform: translateY(-50%);
										top: 50%;
									}
									&-hover {
										display: none;
									}
								}
								.bhg-tooltip {
									flex-grow: 1;
										display: flex;
										justify-content: flex-end;
										margin-right: 15px;
								}
								&:hover {
									&>* {
										opacity: 1;
									}
								}
							}
							&.mod--myb-feature__plain {
								display: flex;
								flex-direction: row-reverse;
								align-items: center;
								padding: 7px 0;
								justify-content: flex-end;
								flex-basis: 100%;
								background-color: transparent;

								.c-myb-trigger-banner__feature {
									&-title,
									&-icon--info {
										display: none;
									}
									&-icon {
										margin-right: 12px;
										//
										width: 23px;
										height: 23px;
										.cmp-icon {
											width: 23px;
											height: 23px;
										}
									}
								}
								&:hover {
									.c-myb-trigger-banner__feature {
										&-icon,
										&-title,
										&-text,
										&-icon--info {
											opacity: 1;
										}
									}
								}
							}
							// FEATURED CARD FOR CATEGORIES - CARDS LAYOUT
							&.mod--myb-feature__card-categories {  
								flex-direction: row;
								background: transparent;
								cursor: default;
								padding: 0;
								margin: 0;
								align-items: flex-start;
								&.mod--myb-feature__card {
									.c-myb-trigger-banner__feature {
										&-title {
											padding-bottom: 0px;
										}
										&-text,
										&-text > * {
											display: block;
										}
										&-icon {										  
											width: 31px;
											height: 31px;
											.c-icon,
											.cmp-icon {
												width: 31px;
												height: 31px;
											}
										}
										&:hover {
											cursor: default;
											opacity: 1;
											.c-myb-trigger-banner__feature {
												&-icon,
												&-title,
												&-title > *
												&-text,
												&-text > * {
													opacity: 1;
												}
											}
										}
									}
								}
								
								&.c-myb-trigger-banner__feature__card:hover>* {
									opacity: 1;
								}
								
							}
						}
					}
					
					// CAROUSEL MODIFIERS MODIFIED
					&.mod--carousel {
						.c-myb-trigger-banner__feature {
							&.mod--myb-feature__card {
								background: white;
								flex-direction: column;
								align-items: initial;
								padding: 14px 20px;
								min-height: 161px;
								margin: 0;
								.c-myb-trigger-banner__feature {
									&-icon {
										margin-right: 10px;
										width: 32px;
										height: 32px;
										.cmp-icon {
											width: 32px;
											height: 32px;
										}
									}
									&-title,
									&-title > * {
										font-size: 16px;
									}
									&-text,
									&-text > * {
										display: block;
									}
									&-icon--info {
										transform: none;
										top: inherit;
									}
									&-hover {
										display: initial;
									}
								}
							}
						}
						// otros features slide
						.c-myb-trigger-banner {
							&__features {
								flex-direction: row;
								flex-wrap: wrap;
								height: 100%;
								height: 75%;
								justify-content: center;
								.c-myb-trigger-banner__feature {
									&.mod--myb-feature__plain {
										flex-direction: column;
										flex: 0 1 35%;
										justify-content: space-around;
										text-align: center;
									}
								}
							}
							&__accordion-text {
								font-size: 16px;
								font-weight: 300;
								text-align: center;
								&-link--dsktp,
								&-link--mbl {
									a {
										color: var(--b-blue);
										font-weight: 500;
									}
								}
								&--dsktp,
								&-link--dsktp {
									display: none;
								}
							}
						}
					}
				}
				&__accordion {
					display: none;
					.accordion {
						&-item {
							&:after {
								content: none;
							}
							&.active {
								.accordion-toggle {
									& > .svg-icon {
										&::after {
											content: "";
											background-color: @white;
											border-radius: 50%;
											width: 32px;
											height: 32px;
											position: absolute;
											right: -4px;
											bottom: -6px;
											z-index: -1;
										}
									}
								}
							}
						}
						&-toggle {
							& > .svg-icon {
								&::before {
									display: inline-block;
									font-family: 'barcelo-ui-kit';
									content: "\e902";
									font-size: 1.2em;
									line-height: 0;
									color: @b-dark-yellow;
								}
							}
							&:hover {
								color: initial;
								& > .svg-icon {
									&::after {
										content: "";
										background-color: @white;
										border-radius: 50%;
										width: 32px;
										height: 32px;
										position: absolute;
										right: -4px;
										bottom: -6px;
										z-index: -1;
									}
								}
							}
							&.mod--centered {
								justify-content: center;
							}
						}
						&-label {
							margin-right: 10px;
						}
						&-content {
							.c-myb-trigger-banner {
								&__features {
									flex-wrap: wrap;
									justify-content: space-between;
									flex-direction: column;
									padding: 0px 20px;
									margin-top: 0;
								}
								&__accordion-text {
									font-size: 16px;
									font-weight: 300;
									text-align: center;
									&-link {
										color: var(--b-blue);
										font-weight: 500;
									}
								}
							}
						}
					}
				}
			}
			.swiper {
				.swiper-pagination {
					// bottom: -50px;
					bottom: -16px;
				}
			}
			.c-cta {
				z-index: initial;
				margin-top: initial;
			}
		}
	}
}

//Carousel plain items into modal
.myb-trigger-banner {
	.c-myb-trigger-banner__content {
		.c-myb-trigger-banner__features {
			&.mod--carousel {
				.c-myb-trigger-banner {
					&__features {
						.c-myb-trigger-banner__feature {
							&.mod--myb-feature__plain {
								flex: 0 1 43%;
							}
						}
					}
				}
			}
		}
	}
}

//LOADING BANNER
.c-loading {
	.c-myb-trigger-banner__content {
		.c-myb-trigger-banner__heading {
			padding-left: 0;
		}
	}
}

//CAROUSEL IN MODAL FIX MOBILE
.c-modal {
	.myb-trigger-banner:not(.mod--compact-carousel) {
		.c-myb-trigger-banner__content {
			padding-bottom: 0px;
			.c-myb-trigger-banner__features {
				&.mod--carousel {
					.swiper {
						.swiper-pagination {
							bottom: -37px;
						}
					}
				}
			}
		}
	}
}

//AVOID UNDESIRED GRADIENT EFFECT
.mod--gradient-myb {
	.c-myb-trigger-banner__logo {
		.cmp-image {
			&::after {
				background: transparent;
			}
		}
	}
}


/* MYBARCELÓ TRIGGER BANNER - DESKTOP  */
@media (min-width: @desktop) {
	.myb-trigger-banner:not(.mod--compact-carousel) {
		.c-myb-trigger-banner {
			flex-direction: row;
			&__content {
				padding: 25px 34px 20px 34px;
				.c-myb-trigger-banner {
					&__logo:not(.c-myb-trigger-banner__logo--categories-standard):not(.c-myb-trigger-banner__logo--categories-intense):not(.c-myb-trigger-banner__logo--categories-unique):not(.c-myb-trigger-banner__logo--categories-all) {
						display: block;
					}
					&__heading {
						margin-bottom: 32px;
					}
					&__hr {
						display: none;
					}
					&__features {
						flex-direction: row;
						padding: 14px 20px;
						margin: 0px -16px;

						justify-content: center;
						margin: 0 auto;

						padding-top: 0;
						.c-myb-trigger-banner {
							&__feature {
								width: 262px;
								width: 256px;
								&-icon {
									.cmp-icon {
									}
								}
								// MODIFIERS
								&.mod--myb-feature__card {
									background: white;
									flex-direction: column;
									align-items: initial;

									margin: 0 10px;
									padding: 14px;
									.c-myb-trigger-banner__feature {

										&-icon {
											margin-right: 10px;
											width: 51px;
											height: 51px;
											.c-icon,
											.cmp-icon {
												width: 51px;
												height: 51px;
											}
										}
										&-title,
										&-title > * {
											font-size: 16px;
										}
										&-text,
										&-text > * {
											display: block;
										}
										&-icon--info {
											transform: none;
											top: inherit;
											display: block;
										}
										&-hover {
											display: initial;
										}
									}
									&:hover {
										&>*:not(.c-myb-trigger-banner__feature-hover){
											opacity: .5;
										}
									}
								}
								&.mod--myb-feature__maxi-card {
									min-height: 161px;

									background: @white;
									flex-direction: column;

									margin: 0 10px;
									padding: 14px;

									.c-myb-trigger-banner__feature {
										min-height: 161px;
										&-icon {
											display: flex;
											order: 1;
											justify-content: space-around;
											align-items: center;
											z-index: 0;
											width: 100%;
											position: relative;
											margin-top: 8px;
											margin-right: 0px;
											// height: inherit;
											height: 67px;
											.cmp-icon {
												width: inherit;
												height: inherit;
											}
											&::before {
												content: "";
												position: absolute;
												width: 100px;
												height: 100px;
												border-radius: 100%;
												border: 2px dashed @b-dusty-blue;
												z-index: 0;
											}
											&::after {
												content: "";
												position: absolute;
												width: 120px;
												height: 120px;
												border-radius: 100%;
												z-index: 0;
												background: @white;
												right: 43px;
											}
											& > .icon {
												background-color: @white;
												z-index: 1;
											}
											&--animation {
												display: flex;
												flex-direction: column;
												justify-content: center;
												align-items: center;
												background-color: @white;
												padding-bottom: 4px;
												z-index: 1;
												min-width: 52px;
												min-height: 68px;
												&-text {
													font-size: 12px;
													font-weight: 100;
													line-height: 14px;
													margin: 2px 0;
													& ~ .icon {
														width: 52px;
														height: auto;
													}
												}
											}
										}
										&-title {
											text-align: center;
											margin-bottom: 14px;
										}
										&-text {
											display: none;
										}
										&-icon--info {
											top: initial;
											bottom: 20px;
											transform: none;
											display: block;
										}
										&-hover {
											display: block;
											&__content {
												& > .c-myb-trigger-banner__feature {
													&-text,
													&-text * {
														display: block;
													}
												}
											}
										}
									}
									.bhg-tooltip {
										display: none;
									}
									&:hover {
										&>*:not(.c-myb-trigger-banner__feature-hover){
											opacity: .5;
										}
									}

									// DOTS ANIMATION
									& {
										.c-myb-trigger-banner__feature-icon {
											&::after {
												animation: dots 2s 2s 1 forwards;
											}
											&--animation {
												& > .icon {
													animation: fadeIn 1s 3.5s both;
												}
												&-text {
													animation: fadeIn 1s 3s both;
													& ~ .icon {
														animation: fadeIn 1s 3s both;
													}
												}
											}
										}
									}

									&:nth-child(2) {
										.c-myb-trigger-banner__feature-icon {
											&::after {
												animation: dots 2s 3.8s 1 forwards;
											}
											&--animation {
												& > .icon {
													animation: fadeIn 1s 5.3s both;
												}
												&-text {
													animation: fadeIn 1s 4.8s both;
													& ~ .icon {
														animation: fadeIn 1s 4.8s both;
													}
												}
											}
										}
									}
									&:nth-child(3) {
										.c-myb-trigger-banner__feature-icon {
											&::after {
												animation: dots 2s 5.6s 1 forwards;
											}
											&--animation {
												& > .icon {
													animation: fadeIn 1s 7.1s both;
												}
												&-text {
													animation: fadeIn 1s 6.6s both;
													& ~ .icon {
														animation: fadeIn 1s 6.6s both;
													}
												}
											}
										}
									}

								}
								&.mod--myb-feature__plain {
									flex-basis: 48%;
									padding: 12px 87px 12px 0;
									.c-myb-trigger-banner__feature {
										&-icon {
											width: 32px;
											height: 32px;
											.cmp-icon {
												width: 32px;
												height: 32px;
											}
										}
									}
								}

								// FEATURED CARD FOR CATEGORIES LAYOUT - CARDS LAYOUT
								&.mod--myb-feature__card-categories {
									width: 350px;
									flex-direction: row;
									background: transparent;
									cursor: default;
									padding: 0;
									margin: 0;
									align-items: center;
									.c-myb-trigger-banner__feature {
										&-title {
											padding-bottom: 0px;
										}
										&-tetx,
										&-text>* {
											display: block; 
										}
										&-icon,
										&-icon >* {
											width: 51px;
											height: 51px;
											.c-icon,
											.cmp-icon {
												width: 51px;
											height: 51px;
											}
										}
									}
									&.mod--myb-feature__card {
										&:hover >* {
											cursor: default;
											opacity: 1;
											.c-myb-trigger-banner__feature {
												&-icon,
												&-title,
												&-text {
													opacity: 1;
												}
											}
										}
									}
									
									&.c-myb-trigger-banner__feature__card {
										&:hover > *:not(.c-myb-trigger-banner__feature-hover) {
											opacity: 1;
										}
									}
								}
							}
						}

						// CAROUSEL MODIFIERS MODIFIED
						&.mod--carousel {
							// otros features slide container
							.c-myb-trigger-banner {
								&__features {
									padding: 0 20px;
									.c-myb-trigger-banner__feature {
										&.mod--myb-feature__plain {
											flex-direction: row-reverse;
											justify-content: flex-end;

											padding: 0px 0 12px 0;
											text-align: left;
											flex: 0 1 39%;
										}

									}
								}
								&__accordion-text {
									padding: 10px 0 30px 0;
									&--dsktp,
									&-link--dsktp {
										display: inline;
									}
									&--mbl,
									&-link--mbl {
										display: none;
									}
								}
							}
							.c-myb-trigger-banner__feature {
								&.mod--myb-feature__card {
									margin: 0 auto;
									width: auto;
									max-width: 262px;
									padding: 14px 14px 10px;
								}
							}
							.swiper-pagination {
								bottom: -20px;
							}
						}
					}
					&__accordion {
						display: block;
						.accordion {
							&-content {
								.c-myb-trigger-banner {
									&__features {
										flex-direction: row;
										margin: 0 auto;
									    justify-content: center;
										.c-myb-trigger-banner__feature {
											&.mod--myb-feature__plain {
												flex-basis: 43%;
												padding: 9pt 10px 0pt 0;
												max-width: 356px;
											}
										}
									}
									&__accordion-text {
										font-size: 16px;
										font-weight: 300;
										text-align: center;
										margin-top: 14px;
										&-link {
											color: var(--b-blue);
											font-weight: 500;
										}
									}
								}
							}
						}
					}
				}

			}
			&__content-right {
				flex-basis: 262px;
				.c-myb-trigger-banner {
					&__switcher {
						padding: 70px 20px 50px 30px;
						display: block;
						&-title,
						&-title > * {
							text-align: center;
						}
						&-title {
							margin-bottom: 12px;
						}
						&-text,
						&-text > * {
							font-size: 16px;
							text-align: center;

							&::before {
								content: "";
							}
						}
						&-text {
							margin-top: 12px;
							margin-bottom: 12px;
						}

						&-text--mbl {
							display: none;
						}
						&-text--dsktp {
							display: block;
						}
						&-img {
							max-width: 130px;
							margin: 0 auto;
							display: block;
						}
					}
				}
			}

			// SWIPER
			.swiper {
				div[data-swiper-slide-index="3"] {
					width: 100%!important;
				}
				.swiper-pagination {
					bottom: -10px;
				}
			}
			// FULL WITH LAST SWIPER SLIDE
			.mod--carousel {
				display: block;
				.carousel {
					width: auto;
					max-width: 1100px;
					display: block;
				}
			}
		}
	}

	//Carousel plain items into modal
	.myb-trigger-banner {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__features {
				&.mod--carousel {
					.c-myb-trigger-banner {
						&__features {
							.c-myb-trigger-banner__feature {
								&.mod--myb-feature__plain {
									flex: 0 1 45%;
								}
							}
						}
					}
				}
			}
		}
	}
	//CAROUSEL IN MODAL FIX DESKTOP
	.c-modal {
		.myb-trigger-banner:not(.mod--compact-carousel) {
			.c-myb-trigger-banner__content {
				padding-bottom: 0px;
				.c-myb-trigger-banner__heading {
					margin-bottom: 14px;
				}
				.c-myb-trigger-banner__features {
					&.mod--carousel {
				    .c-myb-trigger-banner__feature {
                        &.mod--myb-feature__card {
                            .c-myb-trigger-banner__feature-text p {
                                font-size: 15px;
                            }
                        }
				    }
						.swiper {
							.swiper-pagination {
								bottom: -10px;
							}
						}
					}
				}
			}
		}
	}
}


/* MYBARCELÓ TRIGGER BANNER - POLICIES */
.mod--border-top-yellow-myb {
    border-top: 7px solid @b-dark-yellow;
}
.mod--border-top-intense-myb {
    border-top: 7px solid @b-mallow;
}
.mod--border-top-unique-myb {
    border-top: 7px solid @black;
}
.mod--banner-myb-no-shadow {
    .c-myb-trigger-banner {
		box-shadow: none;
	}
}
.mod--remove-logo-myb {
	.c-myb-trigger-banner__content {
		.c-myb-trigger-banner__logo{
			display: none!important;
		}
		.c-myb-trigger-banner__heading {
			padding-left: 0;
		}
	}
}

// FEATURED CARD FOR CATEGORIES - CONTENT LAYOUT POLICIES
.myb-trigger-banner:not(.mod--compact-carousel) {
	
	&.c-myb-trigger-banner__logo--categories-standard,
	&.c-myb-trigger-banner__logo--categories-intense,
	&.c-myb-trigger-banner__logo--categories-unique,
	&.c-myb-trigger-banner__logo--categories-all {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo {
				display: none;
			}
		}
	}
	&.mod--myb-features__categories {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo {
				display: none;
				position: relative;
				margin: 0 auto;
				top: initial;
				left: initial;
				width: 142px;
				&.c-myb-trigger-banner__logo--not-categories {
					display: none!important;
				}
			}
			
			.c-myb-trigger-banner__features {
				padding: 14px 0px;
			}
			.c-myb-trigger-banner__accordion-text {
				margin-bottom: 30px;
				margin-top: 10px;
				text-align: center;
				font-size: 12px;
				font-weight: 700;
			}
			.c-myb-trigger-banner__hr {
				display: none;
			}
		}
	}
	&.c-myb-trigger-banner__logo--categories-standard {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo--categories-standard {
				&.c-myb-trigger-banner__logo {
					display: block;
				}
			}
		}
	}
	&.c-myb-trigger-banner__logo--categories-intense {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo--categories-intense {
				&.c-myb-trigger-banner__logo {
					display: block;
				}
			}
		}
	}
	&.c-myb-trigger-banner__logo--categories-unique {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo--categories-unique {
				&.c-myb-trigger-banner__logo {
					display: block;
				}
			}
		}
	}
	&.c-myb-trigger-banner__logo--categories-all {
		.c-myb-trigger-banner__content {
			.c-myb-trigger-banner__logo--categories-all {
				&.c-myb-trigger-banner__logo {
					display: block;
				}
			}
		}
	}
}

/* MYBARCELÓ TRIGGER BANNER - DESKTOP POLICIES  */
@media (min-width: @desktop) {
	.myb-trigger-banner:not(.mod--compact-carousel) {
		&.c-myb-trigger-banner__logo--categories-standard,
		&.c-myb-trigger-banner__logo--categories-intense,
		&.c-myb-trigger-banner__logo--categories-unique,
		&.c-myb-trigger-banner__logo--categories-all {
			.c-myb-trigger-banner__content {
				.c-myb-trigger-banner__logo {
					display: none;
				}
			}
		}
		&.mod--myb-features__categories {

			.c-myb-trigger-banner__content {
				.c-myb-trigger-banner__logo--not-categories {
					display: none;
				}
				.c-myb-trigger-banner__logo {
					width: 200px;
				}
				.c-myb-trigger-banner__features {
					padding: 0px 56px 14px;
				}
				.c-myb-trigger-banner__heading,
				.c-myb-trigger-banner__heading > * {
					font-size: 20px;
				}
				.c-myb-trigger-banner__accordion-text {
					font-size: 18px;
				}
				.c-container--breakline-over-box {
					margin-top: -4px;
					.c-cta {
						padding: 0 20px;
					}
				}
			}
		}
	}
}