//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* MY BARCELO FEATURES */
.c-myb-features {
    padding-top: 25px;
    &__header {
        display: flex;
        width: 100%;
        min-height: 48px;
        height: 100%;
        &-title {
            display: none;
        }
        &-level {
            width: 33.3%;
            [class$="-box"] {
                padding: 10px 11px 5px 11px;
                border-width: 0px 2px 2px 0px;
                border-style: solid;
                border-color: @white;
                text-align: center;
                height: 100%;
                .c-myb-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    background-size: contain;
                    height: 30px;
                }
            }
            &:last-child {
                [class$="-box"] {
                    border-right: 0px;
                }
            }
        }
    }
    &__content {
        .accordion-toggle {
            height: 48px;
            display: flex;
            align-items: center;
        }
        &-row {
            width: 100%;
            height: 120px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            border-top: 1px solid @black;
            &:first-child {
                border-top: 0px;
            }
            .row {
                &__title {
                    display: block;
                    position: absolute;
                    .z-layout;
                    top: 5px;
                    left: 2.5%;
                    width: 95%;
                    height: auto;
                    padding: 5px;
                    overflow: hidden; /* TODO - CHANGE FOR A  MULTILINE ELLIPSIS*/
                    border-radius: @soft-corners;
                    background: @white;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                }
                &__section {
                    width: 33.3%;
                    padding-top: 60px;
                    height: 120px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 300;
                    &.unique-box {
                        background-color: @grey-20;
                    }
                    &-value {
                        width: 100%;
                        height: 100%;
                        padding: 5px;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        align-items: center;
                        text-align: center;
                        .svg-icon {
                            .mod--size-20;
                        }
                        b {
                            font-size: 18px;
                            font-weight: 700;
                            margin-right: 0.25rem;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-myb-features {
        &__header {
            min-height: 55px;
            height: auto;
            border-bottom: 1px solid @grey-40;
            &-title {
                display: block;
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 700;
            }
            &-level {
                width: 20%;
                height: auto;
                [class$="-box"] {
                    border-bottom: 0px;
                    text-align: center;
                    padding-bottom: 5px;
                    padding-top: 10px;
                    .c-myb-logo {
                        max-width: 160px;
                        min-height: 50px;
                    }
                }
            }
        }
        &__content {
            &-row {
                height: 70px;
                &:first-child {
                    border-top: 1px solid @grey-40;
                }
                .row {
                    &__title {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        top: 0px;
                        left: 0;
                        width: 40%;
                        height: 100%;
                        padding: 5px 5px 5px 30px;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 300;
                    }
                    &__section {
                        width: 20%;
                        padding-top: 0px;
                        height: 100%;
                        font-size: 16px;
                        line-height: 18px;
                        &-value {
                            border-width: 0px 2px 0px 0px;
                            border-style: solid;
                            border-color: @white;
                            flex-wrap: nowrap;
                            b {
                                font-size: 20px;
                                margin-right: 0.25rem;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}
