.c-myb-banner-xl {
    &__wrapper {
        width: 100%;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        background-image: url("https://s7g10.scene7.com/is/image/barcelo/texture-grey-bg?qlt=60&fmt=webp-alpha&cache=validate,on,on&defaultImage=default-dm");
        background-position: right;
        background-size: 100% 100%;

        &-info {
            display: flex;
            flex-direction: column;
            padding: 34px 24px 94px;
            background-size: cover;
            z-index: 1;

            &__image {
                width: auto;
                max-width: 273px;
                max-height: 32px;
                margin-bottom: 16px;
            }

            &__block {
                &-image {
                    display: none;
                }
            }

            &__title {
                display: none;
                color: #384044;
            }

            &__subtitle {
                .cmp-text {
                    font-family: 'Barlow Condensed', sans-serif;
                    font-size: 37.43px;
                    font-weight: 400;
                    line-height: 39.5px;
                    margin-bottom: 8px;
                    color: #384044;
                    b {
                        font-weight: 600;
                    }
                }
            }
            

            &__description {
                .cmp-text {
                    font-family: 'Museo Sans';
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    color: #384044;
                    margin-bottom: 24px;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            &__cta {
                .c-cta {
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 0.005em;
                    padding: 7px 32px;
                    white-space: nowrap;
                    border-radius: 100px;
                }
            }
        }

        &-background {
            position: absolute;
            bottom: 0;
            width: 100%;
            mask-image: url('/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/mybarcelo-mask.svg');
            mask-size: cover;
            mask-repeat: no-repeat;
            -webkit-mask-image: url('/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/mybarcelo-mask.svg');
            -webkit-mask-size: cover;
            -webkit-mask-repeat: no-repeat;

            mask-size: 132%;

            &__image {
                width: 100%;
                height: 120px;
                max-height: 555px;
                .cmp-image {
                    top: -62px;
                    width: 172%;
                    min-width: 586px;
                    left: -19%;
                    min-height: 350px;
                }
            }
        }

        &.mod--compact {
            background-position: right;

            .c-myb-banner-xl__content-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 19px 17px 14.5px 22px;
    
                &__image {
                    width:48px;
                    display: none;
                }
    
                &__title {
                    display: block;
                    font-family: 'TT Norms Pro', sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 16.9px;
                    letter-spacing: 0.01em;
                    text-transform: none;
                    margin-bottom: 0px;
                    color: #232323;
                }
    
                &__subtitle {
                    display: none;
                }

                &__block {
                    width: 68%;
                    &-header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;
                    }
                    &-image {
                        display: inline;
                        width: 37px;
                        height: 31px;

                    }
                }
                
    
                &__description {
                    .cmp-text {
                        font-size: 14px;
                        font-family: 'Museo Sans', sans-serif;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.0025em;
                        margin-bottom: 9px;
                        margin-top: 6px;
                    }
                }
    
                &__buttons {
                    display: flex;
                    flex-direction: row;
                    gap: 24px;
                    justify-content: center;
                }
    
                &__cta {
                    .c-cta {
                        font-size: 16px;
                        line-height: 30px;
                        letter-spacing: 0.005em;
                        padding: 9px 32px;
                        white-space: nowrap;
                    }
                }
            }

            .c-myb-banner-xl__content-background {
                position: absolute;
                top: 0;
                right: 0;
                bottom: unset;
                width: 36%;
                height: 100%;
                mask-image: url('/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/mybarcelo-mask.svg');
                mask-size: auto;
                mask-repeat: no-repeat;
                -webkit-mask-image: url('/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/mybarcelo-mask.svg');
                -webkit-mask-size: auto;
                -webkit-mask-repeat: no-repeat;

                mask-size: 344px;
                left: 70%;
                width: 100%;
    
                &__image {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    .cmp-image {
                        top: -84%;
                        bottom: unset;
                        left: -26%;
                        width: 500px;
                    }
                }
            }
        }
    }

    &__info {
        padding: 32px 40px 43px 40px;
        background-color: #384044;

        &-texts {
            margin-bottom: 28px;
            div {
                margin-bottom: 16px;
            }

            .cmp-text {
                font-family: Museo Sans;
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0.005em;
                color: #fff;

                b, strong {
                    font-weight: 600;
                }

                
                p {
                    margin-bottom: 8px;
                    
                    br {
                     content: "";
                     display: block;
                     height: 8px;
                    }
                }
            }
        }

        &-bottom {

            &__link {
                .cmp-text a {
                    font-family: Museo Sans;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    color: #fff;

                    position: relative;
                    margin-bottom: 8px;
                    text-decoration: none!important;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 2px;
                        bottom: -4px;
                        background-color: var(--b-aquamarine-blue);
                        transition: all 0.3s ease-out;
                        width: 24px;
                    }

                    b, strong {
                        font-weight: 400;
                    }
                }
            }
        }
    }


}

/* MyB Banner XL - TABLET  */
@media (min-width: 769px) {
    .c-myb-banner-xl {

        &__content {
            flex-direction: row;
            background-size: 100%;
            &-info {
                &__image {
                    max-width: 341px;
                    max-height: 40px;
                }
            }
            &-background {
                &__image {
                    height: 134px;
                    .cmp-image {
                        left: 0;
                        top: -154px;
                        width: 100%;
                    }
                }
            }
            &.mod--compact {
                // min-height: 124px;
                min-height: 114px;
                
                .c-myb-banner-xl__content-info {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    padding: 18px 24px 18px 22px;
                    gap: 16px;
                    align-items: center;
                    justify-content: space-between;
        
                    &__image {
                        display: block;
                        width: auto;
                        height: 40px;
                        margin-bottom: 0;
    
                        img {
                            max-height: 43px;
                        }
                    }
    
                    &__block {
                        &-image {
                            display: none;
                        }
                        display: flex;
                        width: 33%;
                        flex-direction: column;
                        flex-grow: 1;
                        gap: 0px;
                    }
        
                    &__title {
                        display: block;
                        font-family: 'TT Norms Pro', sans-serif;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 20.28px;
                        letter-spacing: 0.01em;
                        margin-bottom: 0;
                        color: #232323;
                    }
        
                    &__subtitle {
                        display: none;
                    }
                    
        
                    &__description {
                        .cmp-text {
                            font-size: 14px;
                            font-family: 'Museo Sans', sans-serif;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0.0025em;
                            margin-bottom: 0px;
                            padding-right: 8px;
                            max-width: 400px;
                        }
                    }
        
                    &__buttons {
                        display: flex;
                        flex-direction: row;
                        gap: 24px;
                    }
        
                    &__cta {
                        .c-cta {
                            font-size: 16px;
                            line-height: 30px;
                            letter-spacing: 0.005em;
                            padding: 9px 32px;
                        }
                    }
                }
    
                .c-myb-banner-xl__content-background {
                    position: absolute;
                    mask-size: cover;
                    left: 52%;
                    width: 48%;
                    .cmp-image {
                        width: 100%;
                        top: -166%;
                        left: 0;
                    }
        
                    &__image {
                        width: 100%;
                        height: 100%;
                    }
                }
            }                
        }
        
    }
}
/* MyB Banner XL - DESKTOP  */
@media (min-width: @desktop) {
    .c-myb-banner-xl {
        &__content {
            flex-direction: row;
            max-height: 378px;

            &-info {
                padding: 44px 20px 54px 54px;
                width: 82%;
                &__image {
                    max-width: 321px;
                    max-height: 38px;
                }

                &__title {
                    display: block;
                    font-family: 'Barlow Condensed', sans-serif;
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    margin-bottom: 16px;
                    color: white;
                    text-transform: uppercase;
                }

                &__subtitle{
                    .cmp-text {
                        font-family: 'Barlow Condensed', sans-serif;
                        font-size: 40px;
                        line-height: 40px;
                        margin-bottom: 16px;
                        
                        b {
                            font-weight: 600;
                        }
                    }
                }

                &__description {
                    .cmp-text {
                        margin-bottom: 16px;
                    }
                }

                &__buttons {
                    gap: 24px;
                }

                &__cta {
                    .c-cta {
                        padding: 9px 32px;

                    }
                }
            }

            &-background {
                position: initial;
                width: 68%;
                height: auto;
                margin-top: -20px;
                margin-right: 10px;
                overflow: hidden;

                width: 72%;
                margin-top: -42px;
                mask-size: cover;
                
                &__image {
                    width: 100%;
                    height: 100%;
                    max-height: none;
                    height: 480px;
                    .cmp-image {
                        height: auto;
                        width: 870px;
                        transform: translate(-160px, -158px);
                        top: unset;
                        height: 580px;
                    }
                }
            }

            &.mod--compact {
                .c-myb-banner-xl__content-info {
                    padding: 20px 32px 23px 22px;
                    gap: 12px;
    
                    &__image {
                        height: 48px;
                        width: auto;
                        max-height: none;
                    }

                    &__block {
                        margin-right: 37px;
                        gap: 0;
                    }
    
                    &__description {
                        .cmp-text {
                            font-size: 16px;
                            max-width: none;
                        }
                    }
                }
    
                .c-myb-banner-xl__content-background {
                    max-width: 475px;
                    width: 36%;
                    // height: 180px;
                    height: 200px;

                    right: 0;
                    left: unset;
                    margin: 0;
                    top: -82px;
                    &__image {
                        display: block;
                        height: 410px;
                        .cmp-image {
                            top: -134px;
                            left: unset;
                            right: 202px;
                            width: 614px;
                            transform: none;
                            height: 410px;
                        }
                    }
                }
            }
        }

        &__info {
            padding: 53px 52px 32px 63px;
    
            &-texts {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 24px;
                margin-bottom: 34px;
                p {
                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
                div {
                    margin-bottom: 0;
                }
            }
    
            &-bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                &__image {
                    display: block;
                    width: 138px;
                    height: 30px;
                }
                &__link {
                    .cmp-text a {
                        &::before {
                            content: "";
                            position: absolute;
                            width: 24px;
                        }
                        &:hover::before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}