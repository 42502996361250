@font-face {
  font-family: "Barlow Condensed";
  src: url("../resources/fonts/barlow-condensed/barlow-condensed-regular400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../resources/fonts/barlow-condensed/barlow-condensed-semi_bold600.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("../resources/fonts/barlow-condensed/barlow-condensed-bold700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../resources/fonts/tt-norms-pro/tt-norms-pro-regular400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../resources/fonts/tt-norms-pro/tt-norms-pro-medium500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../resources/fonts/tt-norms-pro/tt-norms-pro-extraBold800.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
