.myb-cta {
    .c-cta {
        text-decoration: none;
        font-family: 'Museo Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: center;
        padding: 14px 54px;
        border-radius: 25px;
        color: #384044;
    }



    // POLICIES
    // COLORS
    &.mod--white {
        .c-cta {
            color: #fff;
        }
    }
    
    &.mod--light-white {
        .c-cta {
            color:#EAEEF1;
        }
    }

    &.mod--light-white {
        .c-cta {
            color:#EAEEF1;
        }
    }

    &.mod--dark-blue {
        .c-cta {
            color: #2F5A76;
        }
    }
    
    // BG COLORS
    &.mod--bg-solid-black {
        .c-cta {
            color: #fff;
            background-color: #384044;
        }
    }

    &.mod--bg-transparent {
        .c-cta {
            background-color: transparent;
            border: 2px solid #384044;
        }
    }

    &.mod--bg-white {
        .c-cta {
            background-color: #fff;
            border: 2px solid #fff;

            &:hover {
                background: #eaeef1;
                border-color: #eaeef1;
            }
        }
    }

    &.mod--bg-light-black {
        .c-cta {
            color: #fff;
            background-color: #384044;
            border: 2px solid #384044;
            &:hover {
                
            }
        }
    }

    // BORDER COLORS
    &.mod--border-white {
        .c-cta {
            border: 2px solid white;
        }
    }

    &.mod--border-dark-blue {
        .c-cta {
            border: 2px solid #2F5A76;
        }
    }

}
