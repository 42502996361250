/* MY BARCELO COMMONS*/

body {
    .c-myb-logo-standard {
        background-image: url("/content/dam/barcelo/commons/icons/logos/myb_standard.png");
    }
    .c-myb-logo {
        .c-myb-logo-standard;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: left center;
    }
    .c-myb-logo-intense {
        background-image: url("/content/dam/barcelo/commons/icons/logos/myb_intense.png");
    }
    .c-myb-logo-unique {
        background-image: url("/content/dam/barcelo/commons/icons/logos/myb_unique.png");
    }
    /* STRUCTURE */
    .myb-left {
    }

    .myb-right {
    }
    /* USER THEME*/

    &.mod--standard,
    &.mod--intense {
        .c-myb-profile-status__content {
            display: flex;
        }
        .c-myb-profile-status__policy {
            display: block;
        }
    }

    &.mod--standard {
        .intense,
        .unique {
            &-txt {
                display: none;
            }
        }
        .c-label {
            background: @myb-standard;
        }
    }

    &.mod--intense {
        .c-myb-logo {
            .c-myb-logo-intense;
        }
        .standard,
        .unique {
            &-txt {
                display: none;
            }
        }
        .c-label {
            background: @myb-intense;
        }
    }

    &.mod--unique {
        .c-myb-logo {
            .c-myb-logo-unique;
        }
        .intense,
        .standard {
            &-txt {
                display: none;
            }
        }
        .c-label {
            background: @myb-unique;
        }
    }

    .standard-box {
        width: 100%;
        height: 100%;
        min-height: 24px;
        background-color: @myb-standard;
        .c-myb-logo {
            .c-myb-logo-standard;
            background-position: center center;
        }
    }
    .intense-box {
        width: 100%;
        height: 100%;
        min-height: 24px;
        background-color: @myb-intense;
        .c-myb-logo {
            .c-myb-logo-intense;
            background-position: center center;
        }
    }
    .unique-box {
        width: 100%;
        height: 100%;
        min-height: 24px;
        background-color: @myb-unique;
        .c-myb-logo {
            .c-myb-logo-unique;
            background-position: center center;
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    body {
        /* STRUCTURE */
        .myb-left,
        .myb-right {
            margin: 16px auto 30px;
        }
        .myb-right {
            padding-left: 60px;
            position: relative;
            &:before {
                content: "";
                border-left: 1px solid @grey-40;
                position: absolute;
                left: 30px;
                width: 1px;
                height: 100%;
            }
        }
        /* USER THEME */
        .standard-box {
            min-height: 30px;
        }
        .intense-box {
            min-height: 30px;
        }
        .unique-box {
            min-height: 30px;
        }
    }
}
